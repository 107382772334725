import React from 'react'
import cx from 'classnames'
import { Link as GatsbyLink } from 'gatsby'

import './Link.scss'

const Link = ({ banner, nav, variant, size, ...props }) => {
	const className = cx('ord-link', {
		'ord-link--nav': nav,
		'ord-link--banner': banner,
		[`ord-link--${variant}`]: !!variant,
		[`ord-link--${size}`]: !!size,
	})

	if (props.href) {
		return (
			<a className={className} {...props}>
				{props.children}
			</a>
		)
	}

	return (
		<GatsbyLink
			className={className}
			activeClassName='ord-link--active'
			{...props}
		/>
	)
}

export default Link
